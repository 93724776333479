import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import common from './common'
import tour from './tour'

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false, encryptionSecret: 's3cr3tEncrypti0n' })
const ss = new SecureLS({ isCompression: false, encryptionSecret: 's3cr3tEncrypti0n' })
ss.ls = window.sessionStorage

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key),
    },
    paths: ['app', 'appConfig', 'verticalMenu', 'auth', 'common'],
  }),
  createPersistedState({
    storage: {
      getItem: key => ss.get(key),
      setItem: (key, value) => ss.set(key, value),
      removeItem: key => ss.remove(key),
    },
    paths: ['tour'],
  })],
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    common,
    tour,
  },
  strict: process.env.DEV,
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isAuthorized } from '@/libs/acl/authorization'
import account from '@/mixins/account'
import Vuex from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    /* {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    }, */
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/account-settings/Settings.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/vendors',
      name: 'vendors',
      component: () => import('@/views/vendor/VendorList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Vendors',
        breadcrumb: [
          {
            text: 'Vendor',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations',
      name: 'locations',
      component: () => import('@/views/locations/LocationList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Locations',
        breadcrumb: [
          {
            text: 'Location',
            active: true,
          },
        ],
      },
    },
    {
      path: '/addons',
      name: 'addons',
      component: () => import('@/views/addons/AddonList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Addons',
        breadcrumb: [
          {
            text: 'Addons',
            active: true,
          },
        ],
      },
    },

    {
      path: '/pickups',
      name: 'pickups',
      component: () => import('@/views/pickup/PickupList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Pickup Service List',
        breadcrumb: [
          {
            text: 'Pickup Service List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tour-register',
      name: 'tour-register',
      component: () => import('@/views/tour/onboarding/TourOnBoarding.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Tour Register',
        layout: 'full',
      },
      children: [
        {
          path: 'step-1/:id',
          name: 'BasicInfo',
          component: () => import('@/views/tour/onboarding/basic-info/BasicInfo.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
        {
          path: 'step-2/:id',
          name: 'Policies',
          component: () => import('@/views/tour/onboarding/policy/Policies.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
        {
          path: 'step-3/:id',
          name: 'Tags',
          component: () => import('@/views/tour/onboarding/tag/Tags.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
        {
          path: 'step-4/:id',
          name: 'Photos',
          component: () => import('@/views/tour/onboarding/photo/Photos.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
        {
          path: 'step-5/:id',
          name: 'Itinerary',
          component: () => import('@/views/tour/onboarding/Itinerary.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
        {
          path: 'step-6/:id',
          name: 'Options',
          component: () => import('@/views/tour/onboarding/option/Options.vue'),
          meta: {
            layout: 'full',
            loginRequired: true,
          },
        },
      ],
    },
    {
      path: '/',
      name: 'tour',
      component: () => import('@/views/tour/TourList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Tour',
        breadcrumb: [
          {
            text: 'Tour',
            active: true,
          },
        ],
      },
    },
    {
      path: '/suggestions',
      name: 'suggestions',
      component: () => import('@/views/suggestion/SuggestionList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Suggestion',
        breadcrumb: [
          {
            text: 'Suggestion',
            active: true,
          },
        ],
      },
    },

    {
      path: '/availability/:id',
      name: 'availability',
      component: () => import('@/views/availability/list-view/AvailabilityList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Tour',
        breadcrumb: [
          {
            text: 'Availability',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (to.path === '/' && to.query.expiry && to.query.id) {
    return account.methods.verifyToken(to.query.id, Vuex, next)
  }
  if (!isAuthorized(to)) {
    return next({ name: 'login' })
  }
  if (to.name === 'login' && account.methods.userLoggedIn(Vuex)) {
    if (account.methods.isUserValid()) {
      return next({ name: 'tour' })
    }
  }
  if (!canNavigate(to)) {
    return next({ name: 'tour' })
  }
  return next()
})
export default router

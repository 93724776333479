import axios from 'axios'
import api from '@/libs/api'
import helper from './helper'

export default {
  mixins: [helper],
  methods: {
    clearUserData() {
      this.$store.commit('auth/updateToken', null)
      this.$store.commit('auth/updateExpiry', null)
      this.$store.commit('auth/updateAccountInfo', {})
      this.$store.commit('auth/updatePermissions', [])
      this.$store.commit('auth/updateRoleStatus', false)
      this.$store.commit('auth/updateAbilities', [])
    },
    isUserValid() {
      return api.getData('account_management/user_account/', true)
        .then(response => response.data.status === 200).catch(() => false)
    },
    userLoggedIn(store) {
      if (store) {
        return store.getters['auth/userLoggedIn']
      }
      return this.$store.getters['auth/userLoggedIn']
    },
    setUserData(response, store) {
      const vuex = this.$store || store
      vuex.commit('auth/updateToken', `Token ${response.token}`)
      vuex.commit('auth/updateExpiry', response.expiry)
      vuex.commit('auth/updatePermissions', response.user.groups)
      vuex.commit('auth/updateAccountInfo', response.user.account_info)
      if (!store) {
        this.$router.replace('/')
          .then(() => {
            this.notificationMessage('success', 'CoffeeIcon', 'Welcome to GoZayaan Admin', 'You have successfully logged in. Now you can start to explore!')
          })
      }
    },
    verifyToken(token, store, next) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}auth/get_new_token/`,
        method: 'get',
        headers: {
          Authorization: token,
        },
      }).then(async response => {
        if (response.status === 200) {
          await this.setUserData(response.data, store)
          next({ name: 'tour' })
        }
        return false
      }).catch(() => {
        window.location.href = process.env.VUE_APP_ADMIN_URL
      })
    },
  },
}
